<template>
  <v-data-table
    :headers="headers_2(headers)"
    :items="summary"
    :items-per-page="rowNumber"
    item-key=""
    @click:row="transmit"
    hide-default-footer
    class="elevation-1"
    dense
    :item-class="() => 'custom-row'"
  >
    <template v-slot:item.Client_Address="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['Client_Address'])"
      />
      <span v-else>{{ item["Client_Address"] }}</span>
    </template>
    <template v-slot:item.Home_Owner="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['Home_Owner'])"
      />
      <span v-else>{{ item["Home_Owner"] }}</span>
    </template>
    <template v-slot:item.Status="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['Status'])"
      />
      <span v-else>{{ item["Status"] }}</span>
    </template>
    <template v-slot:item.Memo="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['Memo'])"
      />
      <span v-else>{{ item["Memo"] }}</span>
    </template>
    <template v-slot:item.payAmount="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['payAmount'])"
      />
      <span v-else>{{ item["payAmount"] | moneyFormat }}</span>
    </template>
    <template v-slot:footer>
      <v-toolbar dark dense class="secondary">
        <v-spacer />
        <v-toolbar-title
          >Total:
          <v-btn color="white" ripple right dark text>{{
            payment | moneyFormat
          }}</v-btn></v-toolbar-title
        >
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { rowNumber, headers_2 } from "@/globals";
import { moneyFormat, processMoney } from "@/functions";
import { mapGetters } from "vuex";
window.moneyFormat = moneyFormat;
export default {
  name: "summary_data_table",
  data: () => ({ rowNumber }),
  methods: {
    headers_2,
    processMoney,
    transmit(p) {
      this.$store.commit("reportType", 0);
      this.$store.commit(
        "reportIndex",
        this.reports.findIndex(
          r =>
            r["Matter Number"] === p["Matter Number"]
            // r["Client_Associations"] === p["Client_Associations"] &&
            // r["Home Owner"] === p["Home Owner"] &&
            // r["Client Address"] === p["Client Address"] &&
            // r["Client Phone"] === p["Client Phone"]
        )
      );
    }
  },
  computed: {
    ...mapGetters({ search: "search", summary: "summaryData" })
  },
  filters: {
    moneyFormat
  },
  props: {
    headers: {
      type: [String, Number],
      default: 3
    },
    payment: {
      type: [String, Number],
      default: 0
    },
    reports: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss">
.custom-row {
  cursor: pointer;
}
</style>
